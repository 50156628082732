<template>
  <div>
    <header style="position: relative;">
      <div style=" margin: 0 auto;">
          <img style="width: 100%;height: 100%;object-fit: contain;" src="../assets/images/bossfront/Group 2176.png" />
      </div>
      <div style="position: absolute;top: 200px;left: 300px;">
        <div>
          <h1 style="font-size: 50px;"><b>服务中心</b></h1>
          <h5 style="font-size: 20px;">服务为本，客户至上，快速响应</h5>
        </div>
      </div>
      <div style="position: absolute;bottom: -110px;text-align: center;width: 100%; size: medium;">
        <div class="boss-content" style="height: 230px; display: flex;justify-content: center;align-items: center;">
          <div class="boss-content" style="box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);background-color: white;display: flex;justify-content: center;align-items: center;">
            <div class="tabhome" :class="{ active: tabPosition === 'frontserve' }"
              @click="router('serve/frontserve', 'frontserve')"><b>售前咨询</b></div>
            <div class="tabhome" :class="{ active: tabPosition === 'afterserve' }"
              @click="router('serve/afterserve', 'afterserve')"><b>售后服务</b></div>
            <div class="tabhome" :class="{ active: tabPosition === 'serve' }" @click="router('serve/serve', 'serve')">
              <b>客户成功服务</b></div>
            <div class="tabhome" :class="{ active: tabPosition === 'complain' }"
              @click="router('serve/complain', 'complain')"><b>投诉反馈</b></div>
          </div>
        </div>
      </div>
    </header>
    <main class="main">
      <div class="counseling">
        <Counseling></Counseling>
      </div>

      <div class="boss-content" style="margin-top: 30px;">
        <div class="title" style="height: 200px;position: relative;">
          <div style="position: relative;">
            <h1 style="font-size: 40px;"><b>服务介绍</b></h1>
            <div style="position: absolute;top: -15px;left: -20px; ">
              <img style="height: 50px;" src="../assets/images/bossfront/Subtract.png" alt="">
            </div>
          </div>
        </div>
        <div style=" margin: 0 auto; height: 80px;position: relative;">
          <p style="text-align: center">
            客户成功顾问服务是以客户为中心，基于客户应用产品的全生命周期，为帮助客户更好更快的利用产品实现业务价值的主动式管家服务。
            主要包括顾问一对一指导、痛点跟进协调解决、场景方案提供、现场服务、项目规划咨询等内容。
          </p>
        </div>
        <div style=" margin: 0 auto">
          <el-row :gutter="40">
            <el-col :xs="8" :md="8">
              <div class="grid-content bg-purple" style="
            position: relative;
                box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
                height: 250px;
                padding: 20px 0px 0px 20px;
              ">
                <h2>新手启动期</h2>
                <li>
                  服务资源引导
                </li>
                <li>
                  产品使用技巧
                </li>
                <li>
                  需求规划引导
                </li>
                <div style="position: absolute;bottom: 0px;right: 0px; ">
                  <img style="height: 150px;" src="../assets/images/bossserve/rocket.png" />
                </div>
              </div>
            </el-col>
            <el-col :xs="8" :md="8">
              <div class="grid-content bg-purple" style="
            position: relative;
                  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
                  height: 250px;
                  padding: 20px 0px 0px 20px;
                ">
                <h2>项目开发期</h2>
                <li>
                  痛点协调解决
                </li>
                <li>
                  场景方案落地
                </li>
                <li>
                  现场深度交流
                </li>
                <li>
                  产品培训赋能
                </li>
                <div style="position: absolute;bottom: 0px;right: 0px; ">
                  <img style="height: 150px;" src="../assets/images/bossserve/browse_activity.png" />
                </div>
              </div>
            </el-col>
            <el-col :xs="8" :md="8">
              <div class="grid-content bg-purple" style="
            position: relative;
                box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
                height: 250px;
                padding: 20px 0px 0px 20px;
              ">
                <h2>稳定维护期</h2>
                <li>
                  协助内部推广
                </li>
                <li>
                  亮点功能推荐
                </li>
                <li>
                  响应效率保障
                </li>
                <li>
                  流失风险预警
                </li>
                <div style="position: absolute;bottom: 0px;right: 0px; ">
                  <img style="height: 150px;" src="../assets/images/bossserve/domain_verification.png" />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      

      <div class="neirong">
        <div class="boss-content" style="margin-top: 30px;">
          <div class="title" style="height: 200px;position: relative;">
          <div style="position: relative;">
            <h1 style="font-size: 40px;"><b>官方支持</b></h1>
            <div style="position: absolute;top: -15px;left: -20px; ">
              <img style="height: 50px;" src="../assets/images/bossfront/Subtract.png" alt="">
            </div>
          </div>
        </div>
          <div style=" margin: 0 auto">
            <el-row :gutter="40">
              <el-col :xs="12" :md="12">
                <div class="grid-content bg-purple">
                  <el-row style="
                  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
                  height: 150px;
                  padding: 20px 0px 0px 20px;
                ">
                    <el-col :xs="4" :md="5">
                      <img src="../assets/images/bossfront/Group 1934.png" />
                    </el-col>
                    <el-col :xs="12" :md="13">
                      <h3>Email</h3>
                      <h5>Chendanging@bossreport.net</h5>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :xs="12" :md="12">
                <div class="grid-content bg-purple">
                  <el-row style="
                  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
                  height: 150px;
                  padding: 20px 0px 0px 20px;
                ">
                    <el-col :xs="4" :md="5">
                      <img src="../assets/images/bossfront/Group 1952(1).png" />
                    </el-col>
                    <el-col :xs="12" :md="13">
                      <h3>电话</h3>
                      <h5>186-8003-7498</h5>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>

      </div>
    </main>
  </div>
</template>

<script>
import Counseling from "../views/Counseling.vue";
export default {
  components: {
    Counseling,
  },
  data() {
    return {
      tabPosition: 'serve'
    };
  },
  computed: {},
  watch: {},
  methods: {
    router(val,tab) {
      this.$router.push("/" + val + "/");
      this.tabPosition=tab
    }
  },
  created() { },
  mounted() { },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.tabhome{
  background-color: white;
  margin-top: 0px;
  height: 76px;
  width: 230px;  
  display: flex;justify-content: center;align-items: center;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
}
.tabhome.active {
  background: url('../assets/images/bossfront/Group 2148.png') no-repeat;
  background-size: 100%;
}
.el-row {
  margin-bottom: 20px;
}

.neirong {
  background: url("../assets/images/jianjie/Rectangle 4559.png");
  background-size: 100%;
  height: 400px;
}

.guanfang {
  background-image: url('../assets/images/jianjie/Rectangle 4559.png');
  background-size: 100%;
}

.bg-purple {
  background: white;
}

.grid-content {
  min-height: 150px;
}

img {
  height: 70px;
}

.title {
  height: 160px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  background: url("../assets/images/bossfront/Group 2176.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 500px;
  position: relative;
  z-index: 1;
}

.counseling {
z-index: 10;
right: 28px;
position: relative;
position: fixed;
width: 56px;
height: 344px;
bottom: 40px;
background: rgba(255, 255, 255, 0.8);
box-shadow: 0px 4px 8px 0px rgba(44, 81, 154, 0.16);
border-radius: 12px 12px 12px 12px;
border: 1px solid #DCE0E4;
}
</style>